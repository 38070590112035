export const constructParams = (params: {
    [key: string]: string | number | undefined;
}) => {
    return Object.values(params).length > 0
        ? Object.entries(params)
              .map(([key, value]) => {
                  if (value === undefined) {
                      return "";
                  }
                  return `${key}=${value}`;
              })
              .filter((queryStringComponent) => queryStringComponent.length > 0)
              .join("&")
        : undefined;
};
